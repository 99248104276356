// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-calendar-tsx": () => import("./../../../src/pages/calendar.tsx" /* webpackChunkName: "component---src-pages-calendar-tsx" */),
  "component---src-pages-competitions-quiz-tsx": () => import("./../../../src/pages/competitions-quiz.tsx" /* webpackChunkName: "component---src-pages-competitions-quiz-tsx" */),
  "component---src-pages-competitions-tsx": () => import("./../../../src/pages/competitions.tsx" /* webpackChunkName: "component---src-pages-competitions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-announcement-template-tsx": () => import("./../../../src/templates/announcementTemplate.tsx" /* webpackChunkName: "component---src-templates-announcement-template-tsx" */)
}

